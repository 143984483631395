import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto';
import './App.css';
import ChartDataLabels from 'chartjs-plugin-datalabels';

function App() {
  const [csvPreview, setCsvPreview] = useState('');
  const [taskName, setTaskName] = useState('');
  const [summary, setSummary] = useState(''); 
  const [protime, setProTime] = useState(''); 
  const [chartData, setChartData] = useState(null);
  const [loading, setLoading] = useState(false);  
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [history, setHistory] = useState([]);
  const [selectedTaskId, setSelectedTaskId] = useState(null);  

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      setIsLoggedIn(true);
      fetchHistory();
    }
  }, []);

  const fetchHistory = async () => {
    const token = localStorage.getItem('token');
    try {
      const response = await fetch('/history/', {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch history');
      }

      const data = await response.json();
      setHistory(data);
    } catch (error) {
      console.error(error.message);
    }
  };

  const handleLogin = async (event) => {
    event.preventDefault();
    try {
      const response = await fetch('/login', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username, password }),
      });

      if (!response.ok) {
        throw new Error('Login failed');
      }

      const data = await response.json();
      const token = data.access_token;
      localStorage.setItem('token', token);
      setIsLoggedIn(true);
      setErrorMessage('');
      fetchHistory();
    } catch (error) {
      console.error(error.message);
      setErrorMessage('Invalid username or password');
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const content = e.target.result;
        const lines = content.split('\n');
        let table = '<table border="1" cellpadding="5">';

        const parseCSVLine = (line) => {
          const regex = /("([^"]|"")*"|[^,]+)(?=\s*,|\s*$)/g;
          let cells = [];
          let match;
          while ((match = regex.exec(line))) {
            cells.push(match[0].replace(/(^"|"$)/g, '').replace(/""/g, '"'));
          }
          return cells;
        };

        lines.slice(0, 10).forEach((line) => {
          const cells = parseCSVLine(line);
          table += `<tr>${cells.map((cell) => `<td>${cell}</td>`).join('')}</tr>`;
        });

        table += '</table>';
        setCsvPreview(table);
      };
      reader.readAsText(file);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);  
    setSummary('');
    setProTime(''); 
    setChartData(null);
    setSelectedTaskId(null);

    const fileInput = document.getElementById('file');
    const formData = new FormData();
    formData.append('file', fileInput.files[0]);

    try {
      const token = localStorage.getItem('token');

      const response = await fetch('/process_csv/', {
        method: 'POST',
        body: formData,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      alert('CSV processing has started in the background. We will notify you when it\'s complete.');
      startPolling();  
    } catch (error) {
      console.error(error.message);
      setErrorMessage('Failed to start the CSV processing task.');
      setLoading(false);  
    }
  };

  const startPolling = () => {
    const intervalId = setInterval(() => {
      pollTaskStatus(intervalId);
    }, 5000);  
  };


  const pollTaskStatus = (intervalId) => {
    const token = localStorage.getItem('token');
  
    fetch('/task_status/', {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => response.json())
      .then((taskData) => {
        if (taskData.status === 'completed') {
          console.log('Task completed');
          setSummary(taskData.summary);
          setProTime(taskData.processing_time);
          setSelectedTaskId(taskData.task_id);  
  
          const chartData = {
            labels: ['DeepL', 'GPT4o Mini', 'Gemini 1.5 Flash', 'Google Translate'],
            datasets: [
              {
                label: 'Source to Target (ja_en)',
                data: [
                  taskData.avg_score_deepl_src_tgt,
                  taskData.avg_score_mini_src_tgt,
                  taskData.avg_score_flash_src_tgt,
                  taskData.avg_score_google_src_tgt
                ],
                backgroundColor: 'skyblue',
              },
              {
                label: 'Target to Source (en_ja)',
                data: [
                  taskData.avg_score_deepl_tgt_src,
                  taskData.avg_score_mini_tgt_src,
                  taskData.avg_score_flash_tgt_src,
                  taskData.avg_score_google_tgt_src
                ],
                backgroundColor: 'orange',
              },
            ],
          };
          setChartData(chartData);
          setLoading(false);  
          clearInterval(intervalId); 
          fetchHistory();  
          alert('CSV processing is complete!');
        } else if (taskData.status === 'processing') {
          
        } else if (taskData.status === 'failed') {
          console.error('Task failed:', taskData.error_message);
          setErrorMessage(`Task failed: ${taskData.error_message}`);  
          setLoading(false);  
          clearInterval(intervalId);  
          alert(`Error: ${taskData.error_message}`);
        }
      })
      .catch((error) => {
        console.error('Error fetching task status:', error);
        setErrorMessage('Failed to retrieve task status. Please try again later.');
        setLoading(false); 
        clearInterval(intervalId);  
      });
  };

  const handleHistoryClick = (task) => {
    setSelectedTaskId(task.task_id);  
    setSummary(task.summary);
    setProTime(task.processing_time)
    setTaskName(`Task ${task.task_id}`);

    const chartData = {
      labels: ['DeepL', 'GPT4o Mini', 'Gemini 1.5 Flash', 'Google Translate'],
      datasets: [
        {
          label: 'Source to Target (ja_en)',
          data: [
            task.avg_score_deepl_src_tgt,
            task.avg_score_mini_src_tgt,
            task.avg_score_flash_src_tgt,
            task.avg_score_google_src_tgt
          ],
          backgroundColor: 'skyblue',
        },
        {
          label: 'Target to Source (en_ja)',
          data: [
            task.avg_score_deepl_tgt_src,
            task.avg_score_mini_tgt_src,
            task.avg_score_flash_tgt_src,
            task.avg_score_google_tgt_src
          ],
          backgroundColor: 'orange',
        },
      ],
    };
    setChartData(chartData);
  };

 
  const handleDownloadPdf = async (taskId) => {
    const token = localStorage.getItem('token');
    try {
      const response = await fetch(`https://nmteval.trial.hdks.co.jp/history/${taskId}/pdf/`, {  
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        const errorText = await response.text();  
        console.error('Error response:', errorText);
        throw new Error('Failed to download PDF');
      }

      const blob = await response.blob();  
      const url = window.URL.createObjectURL(blob);  
      const link = document.createElement('a');  
      link.href = url;
      link.download = `task_${taskId}_history.xlsx`;  
      document.body.appendChild(link);  
      link.click();  
      document.body.removeChild(link);  
    } catch (error) {
      console.error('Error downloading PDF:', error.message);
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    setIsLoggedIn(false);
    setTaskName('');
    setSummary('');
    setChartData(null);
    setSelectedTaskId(null);
    setHistory([]);
  };

  return (
    <div className="App">
      {!isLoggedIn ? (
        <div className="login-container">
          <div className="login-header">
            <h1 className="product-title">Translation Evaluation Tool</h1>
            <h2 className="login-title">Login to Your Account</h2>
          </div>
          <div className="login-form">
            <form onSubmit={handleLogin} className="form">
              <div className="form-group">
                <label htmlFor="username" className="form-label">Username</label>
                <input
                  type="text"
                  id="username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  className="form-input"
                  placeholder="Enter your username"
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="password" className="form-label">Password</label>
                <input
                  type="password"
                  id="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="form-input"
                  placeholder="Enter your password"
                  required
                />
              </div>
              {errorMessage && <p className="error-message">{errorMessage}</p>}
              <button type="submit" className="login-button">Login</button>
            </form>
          </div>
        </div>
      ) : (
        <div className="content">
          <div id="history-sidebar" className="sidebar">
            <h3 className="sidebar-title">History</h3>
            <ul className="sidebar-list">
              {history.map((task, index) => (
                <li key={index} className="sidebar-item" onClick={() => handleHistoryClick(task)}>
                  Task {task.task_id} - {new Date(task.created_at).toLocaleString()}
                </li>
              ))}
            </ul>
          </div>
          <div className="logout-button">
            <button onClick={handleLogout}>Logout</button>
          </div>
          <h1>Translation Evaluation Tool</h1>

          <div className="container">
            <div className="left-panel">
              <label htmlFor="file">Upload CSV:</label>
              <input type="file" id="file" name="file" accept=".csv" required onChange={handleFileChange} />
              <button type="submit" onClick={handleSubmit}>Submit</button>
              <div id="csv-preview" style={{ marginTop: '20px' }}>
                <h3>CSV Preview:</h3>
                <div dangerouslySetInnerHTML={{ __html: csvPreview }} />
              </div>
            </div>

            <div className="right-panel">
              {taskName && (
                <div className="task-name">
                  <h3>{taskName}</h3>
                </div>
              )}
              {loading && (
                <div className="spinner-container">
                  <div className="spinner"></div>  
                  <p>Processing, please wait...</p>
                </div>
              )}

              {chartData && (
                <div>
                  <div id="chart">
                    <h3>Plot:</h3>
                    <Bar
                      data={chartData}
                      options={{
                        responsive: true,
                        maintainAspectRatio: true,
                        plugins: {
                          legend: {
                            display: true,
                            position: 'bottom',
                          },
                          tooltip: {
                            enabled: true,
                            callbacks: {
                              label: function (context) {
                                let label = context.dataset.label || '';
                                if (label) {
                                  label += ': ';
                                }
                                if (context.parsed.y !== null) {
                                  label += context.parsed.y.toFixed(2);
                                }
                                return label;
                              },
                            },
                          },
                          datalabels: {
                            display: true,
                            align: 'end',
                            anchor: 'end',
                            formatter: (value, context) => {
                              return value.toFixed(2); 
                            },
                            color: '#000',
                          },
                        },
                        scales: {
                          x: {
                            ticks: {
                              color: '#000',
                            },
                          },
                          y: {
                            ticks: {
                              color: '#000',
                              stepSize: 0.5,
                              callback: function (value, index, values) {
                                return value.toFixed(2);
                              },
                            },
                            min: 0,
                            max: 100,
                          },
                        },
                      
                      }}
                      plugins={[ChartDataLabels]} 
                    />
                  </div>
                </div>
              )}
              {summary && (
                <div id="summary">
                  <h3>Summary:</h3>
                  <p id="summary-text">{summary}</p>
                  <p>Processing Time: {protime.toFixed(2)} minutes</p> 
                </div> 
              )}
              {selectedTaskId && (
                <div id="pdf-download">
                  <h3>Download Detailed Result:</h3>
                  <button onClick={() => handleDownloadPdf(selectedTaskId)}>
                    Download 
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default App;
